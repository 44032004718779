<template>
    <b-card>
        <b-input-group class="mb-1 w-25 d-flex ml-auto">
            <b-input-group-prepend>
                <b-input-group-text>
                    <feather-icon icon="SearchIcon"></feather-icon>
                </b-input-group-text>
            </b-input-group-prepend>
            <b-input v-model="filter"></b-input>
        </b-input-group>
        <b-table ref="table" striped responsive :filter="filter" :items="brands" :fields="fields" :per-page="perPage" :current-page="currentPage" hover @filtered="onTableFiltered">
            <template #cell(created_at)="data">
                {{ data.value | humanizeDate }}
            </template>
            <template #cell(updated_at)="data">
                {{ data.value | humanizeDate }}
            </template>
        </b-table>
        <b-pagination class="mt-3" v-model="currentPage" align="right" :total-rows="rows" :per-page="perPage" first-number></b-pagination>

        <b-btn variant="primary" @click="openModalCreateBrand">Créer une marque</b-btn>
        <generic-confirm-modal ref="modal-create-brand" title="Création d'une marque" @on-accept="crateBrand" @on-cancel="closeModalCreateBrand">
            <b-overlay :show="creatingBrand">
                <b-form @submit.stop.prevent>
                    <b-form-group label="Name" label-for="input-brand">
                        <b-input id="input-brand" v-model="brandName" :state="ruleBrandName || !showFormError ? null : false"></b-input>
                        <b-form-invalid-feedback :state="ruleBrandName || !showFormError ? null : false"> Ce champ doit être renseigné </b-form-invalid-feedback>
                    </b-form-group>
                </b-form>
            </b-overlay>
        </generic-confirm-modal>
    </b-card>
</template>

<script>
    import { mapGetters } from 'vuex';
    export default {
        props: {
            brands: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                fields: [
                    { key: 'name', label: 'Name' },
                    { key: 'active', label: 'Status' },
                    { key: 'created_at', label: 'Date de création' },
                    { key: 'updated_at', label: 'Date de mise à jour' },
                ],
                perPage: 10,
                currentPage: 1,
                filter: '',
                rows: 0,
                brandName: '',
                showFormError: false,
                creatingBrand: false,
            };
        },
        computed: {
            ...mapGetters({
                successToast: 'app/successToast',
                errorToast: 'app/errorToast',
            }),
            ruleBrandName() {
                return this.brandName !== '';
            },
        },
        watch: {
            brands: {
                immediate: true,
                handler(val) {
                    this.setRows(val);
                },
            },
        },
        methods: {
            setRows(list) {
                this.$set(this, 'rows', list.length);
            },

            onTableFiltered(list) {
                this.setRows(list);
            },

            openModalCreateBrand() {
                this.$refs['modal-create-brand'].open();
            },

            crateBrand() {
                if (!this.ruleBrandName) return (this.showFormError = true);
                this.creatingBrand = true;
                this.$store
                    .dispatch('app/createBrand', { name: this.brandName })
                    .then((res) => {
                        this.$toast(this.successToast('La marque à été ahjouté!'));
                        this.closeModalCreateBrand();
                        this.$emit('on-refresh');
                    })
                    .catch((e) => {
                        this.$toast(this.errorToast("Erreur lors de l'ajout de la marque"));
                    })
                    .finally(() => {
                        this.creatingBrand = false;
                    });
            },
            closeModalCreateBrand() {
                this.$refs['modal-create-brand'].close();
                this.showFormError = false;
                this.brandName = '';
            },
        },
    };
</script>

<style></style>
